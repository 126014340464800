import { BaseConfig } from '@danone-global/internal/react/core'
import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment: BaseConfig = {
  storeName: 'Nutricia',
  siteName: 'dach-nutricia',
  siteSlug: 'dach-nutricia',
  defaultLocale: 'de-DE',
  localeConfigs: [
    {
      locale: 'de-AT',
      country: 'AT',
      currency: 'EUR',
      initMessages: () =>
        import('../translations/de_AT.json').then((r) => r.default),
      urls: {
        faq: 'https://shop.nutricia.de/at/produkte/faq.html',
        contact: 'mailto:onlineshop@nutricia.de',
        dataCollection:
          'https://shop.nutricia.de/at/datenschutzerklaerung.html',
      },
    },
    {
      locale: 'de-DE',
      country: 'DE',
      currency: 'EUR',
      initMessages: () =>
        import('../translations/de_DE.json').then((r) => r.default),
      urls: {
        faq: 'https://shop.nutricia.de/produkte/faq.html',
        contact: 'mailto:onlineshop@nutricia.de',
        dataCollection: 'https://shop.nutricia.de/datenschutzerklaerung.html',
      },
    },
  ],
  providerId: 'danis_eu',
  deliveryCategory: 'loprofin-bread',
  ctHost: 'https://api.europe-west1.gcp.commercetools.com',
  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',
  ctStoreKey: 'NUTRICIA',
  analytics: {
    tracker: null,
    language: 'en-GB',
    defaultCategory: 'Baby food',
  },
  passwordValidation: {
    mustHaveLowercaseAndUppercase: true,
    specialCharacters: 'DACH',
  },
  checkout: {
    stepAddress: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/de-step-address'),
    ),
    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),
    checkboxes: [
      {
        name: 'isMarketingOptedIn',
        required: false,
        store: true,
        label: messages.isMarketingOptedIn,
      },
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTerms,
      },
      {
        name: 'agreeDataSecurity',
        required: true,
        store: false,
        label: messages.agreeDataSecurity,
      },
    ],
  },
  signupStaticFields: {
    clubId: 'DE NUTRICIA',
    brand: 'Nutricia',
    division: 'SN',
    region: 'EMEA',
  },
}
