import AddToCart from '@danone-global/widgets/add-to-cart-button'
import Checkout from '@danone-global/widgets/checkout'
import ManageSubscriptionButton from '@danone-global/widgets/manage-subscription-button'
import OrderCompleted from '@danone-global/widgets/order-completed'
import OrderDeeplink from '@danone-global/widgets/order-deeplink'
import OrderHistory from '@danone-global/widgets/order-history'
import Price from '@danone-global/widgets/price'
import PriceChannelSelector from '@danone-global/widgets/price-channel-selector'
import ProductRating from '@danone-global/widgets/product-rating'
import Reviews from '@danone-global/widgets/reviews'
import ShoppingCart from '@danone-global/widgets/shopping-cart'
import ShoppingCartContent from '@danone-global/widgets/shopping-cart-content'
import VariantSelector from '@danone-global/widgets/variant-selector'

/**
 * Define which widgets are available on PRODUCTION
 */
export const widgets = [
  AddToCart,
  Checkout,
  ManageSubscriptionButton,
  OrderCompleted,
  OrderDeeplink,
  OrderHistory,
  Price,
  PriceChannelSelector,
  ProductRating,
  Reviews,
  ShoppingCart,
  ShoppingCartContent,
  VariantSelector,
]
